@use 'global' as *;
// ---------------------------------------------------------

.container {
  overflow: hidden;
  position: relative;
}

.hero {
  padding-bottom: 80px;

  @include media($medium, $large-lowest) {
    align-items: center;
    display: grid;
    grid-template-columns: 50% 1fr;
    margin: 0 auto;
    padding-bottom: 0;
  }

  @include media($large-lowest) {
    align-items: center;
    display: grid;
    grid-template-columns: 660px 1fr;
    margin: 0 auto;
    max-width: var(--global-max-width);
    padding-bottom: 0;
  }

  @include media($large-highest) {
    padding-bottom: 0;
  }
}

// --------------------------------------------------------- Hero content

.hero_col_left {
  display: grid;
  justify-content: center;
  margin-top: 40px;
  padding: 40px 1.5rem;
  text-align: center;

  @include media($medium) {
    text-align: left;
  }
}

.hero_text {
  white-space: pre-wrap;

  h1 {
    @include media($medium, down) {
      margin: 0 auto;
      margin-bottom: 1.5rem;
      max-width: 600px;
    }
  }

  @include media($small-lowest, $medium-lowest) {
    white-space: normal;
  }
}

.button_primary {
  margin-top: 20px;
}

.carousel_image {
  display: none;

  @include media($medium) {
    display: block;
  }
}

// --------------------------------------------------------- Hero carousel

.carousel {
  @include media($medium) {
    margin: 80px auto;
    padding: 1rem;
  }

  .cards_label {
    @include label;

    margin-bottom: 0;
    padding-top: 1rem;
    text-align: center;

    @include media($medium) {
      margin-left: 14px;
      padding-top: 0;
      text-align: left;
    }
  }

  :global(.swiper-pagination) {
    @include media($medium, $x-large) {
      left: 24vw;
      text-align: left;
    }
  }
}

.label_text {
  @include label;

  margin-bottom: 1rem;
}
